import { IGuestRecord, IGuestResponse, isGuestResponse } from "./GuestApi";
import { ApiBasePath } from "./ApiBase";

export interface IMember {
    ID: string;
    order: string;
    OnLeave: string;
    Name: string;
    Instrument: string;
}

export interface IResponse {
    returnType: string;
}

export interface IErrorResponse extends IResponse {
    status: number;
}

export interface IMembersResponse extends IResponse {
    returnType: "members";
    results: IMember[];
}

function isMemberResponse(r: IResponse): r is IMembersResponse {
    return (r.returnType === "members");
}


export interface IUser {
    user_id: string;
    username: string;
    first_name: string;
    last_name: string;
}

export interface IUsersResponse {
    returnType: "users";
    results: IUser[];
}

export function isUsersResponse(r: IResponse): r is IUsersResponse {
    return (r.returnType === "users");
}

export interface IGig {
    GigID: string;
    StartTime: string;
    EndTime: string;
    Title: string;
    HtmlDesc?: string;
    Visible: string;
    Map: string;
}

export interface IGigsResponse {
    returnType: "gigs";
    results: IGig[];
}

export interface IPlaylist {
    GigID: string;
    Playlist: string;
}

export interface IPlaylistResponse {
    returnType: "playlist";
    results: IPlaylist[];
}

export interface IGigResponse {
    bounds: { MN: string; MX: string; PR: string; NX: string };
    playlist: null | IPlaylist;
    gig: null | IGig;
}
export interface ISimpleAttendance {
    attending: string | null;
}

export interface IAttendance extends IGig, ISimpleAttendance {
    memberID: string;
}

export interface IAttendanceResponse {
    returnType: "attendance";
    results: IAttendance[];
}

export interface IMemberAttendance {
    attending: string | null;
    gigID: string;
    ID: string;
    Instrument: string;
    Name: string;
}

export interface IMemberAttendanceResponse {
    returnType: "attendance";
    results: IMemberAttendance[];
}

export class Api {
    public static _instance?: Api;

    public static get Instance() {
        if (!Api._instance) {
            Api._instance = new Api();
        }
        return Api._instance;
    }

    private async get(cmd: string): Promise<IResponse | IErrorResponse> {
        const response = await fetch(`${ApiBasePath}/${cmd}`, {
            method: 'GET',
            credentials: 'include',
            mode: 'cors'
        });

        if (response.ok) {
            return await response.json() as IResponse;
        } else return ({
            returnType: "error",
            status: response.status
        });
    }

    private async put(cmd: string, payload: any, headers?: HeadersInit) {
        console.log("data put");
        const response = await fetch(`${ApiBasePath}//${cmd}`, {
            method: 'POST',
            headers,
            mode: 'cors',
            credentials: 'include',
            body: JSON.stringify(payload)
        });
        console.log("data put");

        if (response.ok) {
            return await response.text();
        } else return ({
            returnType: "error",
            status: response.status
        });

    }

    private async post(cmd: string, payload: any, headers?: HeadersInit): Promise<IResponse|IErrorResponse> {
        console.log("data post");
        const response = await fetch(`${ApiBasePath}/${cmd}`, {
            headers,
            method: 'POST',
            mode: 'cors',
            credentials: 'include',
            body: payload
        });
        console.log("data post");

        if (response.ok) {
            return await response.json() as IResponse;
        } else return ({
            returnType: "error",
            status: response.status
        });

    }

    public async getMembers() {
        const ret = await this.get("get_members.php");
        return isMemberResponse(ret) ? ret : undefined;

    }

    public async getUsers() {
        const ret = await this.get("get_userlist.php");
        return isUsersResponse(ret) ? ret : undefined;
    }


    public async postGuests(guests: Partial<IGuestRecord>[]) {
        const ret = await this.post("introduce_guests.php", JSON.stringify({ guests }, null, 2)) as IGuestResponse;
        return isGuestResponse(ret) ? ret : undefined;

    }

    private constructor() {
        Api._instance = this;
    }
}
