import * as React from 'react';
import { SafePureComponent } from './utils/SafePureComponent';
import { Link } from '@fluentui/react';
import { mergeStyleSets } from '@fluentui/react';

const css = mergeStyleSets({
    info: {
        marginBottom: "6pt",
        selectors: {
            p: {
                margin: "0cm",
                marginTop: "6pt",
            },
            img: {
            }
        }
    },
    narrow: {
        maxWidth: "40%",
        display: "inline-block"
    },
    mine: {

    }
});

function No({ label }: { label: string }) {
    const escapedLabel = encodeURIComponent(label);
    return <>
        <h2>{label}</h2>
        <Link className={css.mine} href={`mailto:rearcomm@camsailingclub.org.uk?body=${escapedLabel}%0D%0A&subject=That%20is%20mine!%20${escapedLabel}`}>
            That&rsquo;s mine.   Please don&rsquo;t sell it.
        </Link>
    </>;
}

function abandoned(src: string, className?: string) {
    return <img className={className} alt="Unlabelled Trailer/Trolley" src={`/abandoned/${src}`} />;
}

export class Unlabelled extends SafePureComponent {

    public render() {
        return <div className={css.info}>
            <h1>Unlabelled property</h1>
            <p>Thank you for taking the time to look over the unidentified property on the grounds</p>
            <p>The rules state that all property should be clearly labelled.</p>
            <p>The club has the right to dispose of unclaimed property after three months, and everything shown here will
                be auctioned in early March and any that has no bids will be disposed of in April unless you send the Rear Commodore a message informing that
                the items are yours and pay any outstanding fees due to the treasurer.
            </p>

            <No label="Lot 1" />
            <p>A large road trailer, as seen last year and this year</p>

            <div>
                {abandoned("135227.jpg", css.narrow)}
                {abandoned("135227-2021.jpg", css.narrow)}
            </div>

            <No label="Lot 2" />
            <p>A very heavy road trailer on little hollow, rusting away</p>
            <div>
                {abandoned("IMG_20211117_110645.jpg")}
            </div>

            <No label="Lot 3" />
            <p>A narrow red road trailer, on little hollow</p>
            <div>
                {abandoned("IMG_20211117_110701.jpg")}
            </div>

            <No label="Lot 4" />
            <p>A rusting blue trailer with no boat supports on little hollow</p>
            <div>
                {abandoned("IMG_20211117_110708.jpg")}
            </div>

            <No label="Lot 5" />
            <p>A yellow road trailer near the workshed</p>
            <div>
                {abandoned("IMG_20211117_111814.jpg")}
            </div>

            <No label="Lot 6" />
            <p>A blue road trailer near the work shed with mast support</p>
            <div>
                {abandoned("IMG_20211117_112228.jpg")}
            </div>
            <No label="Lot 7" />
            <p>A black road trailer near the work shed with mast support but no wheels</p>
            <div>
                {abandoned("IMG_20211117_112356.jpg")}
            </div>
            <No label="Lot 8" />
            <p>A road trailer near the workshed with a makeshift dinghy trailer tied on</p>
            <div>
                {abandoned("IMG_20211117_114210.jpg")}
            </div>
            <No label="Lot 9" />
            <p>A maroon launch trolley near the workshed missing a wheel</p>
            <div>
                {abandoned("IMG_20211117_114358.jpg")}
            </div>
            <No label="Lot 10" />
            <p>A launch trolley near the workshed with narrow solid wheels</p>
            <div>
                {abandoned("IMG_20211117_114408.jpg")}
            </div>
            <No label="Lot 11" />
            <p>Various trolleys and trailers in the hedge, including one marked staples</p>
            <div>
                {abandoned("IMG_20211117_114451.jpg")}
            </div>
            <No label="Lot 12" />
            <p>A wooden mirror dinghy at the end of the oppy line in a bad state of repair</p>
            <div>
                {abandoned("IMG_20211117_115338.jpg")}
            </div>
            <No label="Lot 13" />
            <p>A Lark dinghy with no mast at the end of the oppy line</p>
            <div>
                {abandoned("IMG_20211117_115347.jpg")}
            </div>
            <No label="Lot 14" />
            <p>A wooden optimist-like boat with a hole in it at the end of the oppy line</p>
            <div>
                {abandoned("IMG_20211117_115402.jpg")}
            </div>
            <No label="Lot 15" />
            <p>A wide launch trolley in the fast handicap line</p>
            <div>
                {abandoned("IMG_20211117_123226.jpg")}
            </div>
            <No label="Lot 16" />
            <p>A large trolley with no wheels in the fast handicap line</p>
            <div>
                {abandoned("IMG_20211117_123417.jpg")}
            </div>
            <No label="Lot 17" />
            <p>A Launch trolley labelled Reader in the fast handicap line</p>
            <div>
                {abandoned("IMG_20211117_123503.jpg")}
            </div>
            <No label="Lot 18" />
            <p>An RS200 road trailer in the fast handicap line</p>
            <div>
                {abandoned("IMG_20211117_123621.jpg")}
            </div>
            <No label="Lot 19" />
            <p>Various wood chocks near the pile of hardcore in the car park</p>
            <div>
                {abandoned("IMG_20211117_142255.jpg")}
            </div>
            <No label="Lot 20" />
            <p>A yellow frame of a trailer near the road trailer park behind the workshed</p>
            <div>
                {abandoned("IMG_20211117_111703.jpg")}
            </div>
        </div>;

    }
}

export default Unlabelled;
