import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router';
import { SafePureComponent } from './utils/SafePureComponent';
import { Loading } from 'Components/Loading';
import { ILoginProps } from 'Components/Login';

import Welcome from './Welcome';
import { globalCss } from './utils/globalStyles';
import Info from './Info';
import { Unlabelled } from './Unlabelled';

const initialState = Object.freeze({
})

export class BodyMin extends SafePureComponent<ILoginProps, typeof initialState> {
    public state = initialState; 

    public componentDidMount() {
        super.componentDidMount();
    }

    public render() {

        return (
            <div className={globalCss.constrainedContent}>
                <Suspense fallback={<Loading />}>
                    <Switch>
                        <Route path="/" exact render={(_props) => 
                            <Welcome />
                        } />
                        <Route path="/agminfo" render={(_props) =>
                            <Info />
                        } />
                        <Route path="/unlabelled" render={(_props) =>
                            <Unlabelled />
                        } />

                    </Switch>
                </Suspense>
            </div>
        );
    }

}
