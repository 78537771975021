import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router';
import { SafePureComponent } from './utils/SafePureComponent';
import { Loading } from 'Components/Loading';
import { ILoginProps } from 'Components/Login';
import { Logout } from 'Components/Logout';

import { SendEmail } from 'AdminPages/SendEmail';
import Welcome from './Welcome';
import { globalCss } from './utils/globalStyles';
import { Guests } from './Apps/Guests';

const initialState = Object.freeze({
})

export class Body extends SafePureComponent<ILoginProps, typeof initialState> {
    public state = initialState; 

    public componentDidMount() {
        super.componentDidMount();
    }

    public render() {

        return (
            <div className={globalCss.constrainedContent}>
                <Suspense fallback={<Loading />}>
                    <Switch>
                        <Route path="/" exact render={(_props) => 
                            <Welcome />
                        } />
                        <Route path="/guests" exact render={(_props) => <Guests />} />
                        <Route path="/sendEmail" exact render={(_props) => 
                            <SendEmail />
                        } />
                        <Route path="/logout" render={(_props) => <Logout loginChange={this.props.loginChange} />} />
                    </Switch>
                </Suspense>
            </div>
        );
    }

}
