import * as React from 'react';
import { SafePureComponent } from './utils/SafePureComponent';
import { Link } from 'react-router-dom';
import { mergeStyleSets } from '@fluentui/react';
import { MemberDocsPath } from 'Api/ApiBase';

const css = mergeStyleSets({
    info: {
        marginBottom: "6pt",
        selectors: {
            p: {
                margin: "0cm",
                marginTop: "6pt",

            }
        }
    }
});

function Doc({ filename, desc }: {filename: string, desc: string}) {
    return <a href={`${MemberDocsPath}/${filename}`} rel="noopener noreferrer" target="_blank">{`${desc}`}</a>;
}

export class Info extends SafePureComponent {

    public render() {
        return <div className={css.info}>
            <h1>Important AGM Documents</h1>
            <ul>
                <li><Doc filename="2020_CSC_AGM_Minutes.pdf" desc="2020 AGM Minutes" /></li>
                <li><Doc filename="Agenda2021.pdf" desc="2021 Agenda" /></li>
                <li><Doc filename="CSC_Fees_2022.pdf" desc="2022 Fees Schedule" /></li>
                <li><Doc filename="CSC_Vision_V1.pdf" desc="Development Plan (Vision V1)" /></li>
            </ul>
            <h2>Unlabelled Property</h2>
            <p>While you&rsquo;re here, why not see if any of the <Link to="/unlabelled"> unlabelled property on the grounds</Link> belongs to you by following the link</p>
        </div>;

    }
}

export default Info;
