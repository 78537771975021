import { FontSizes, mergeStyleSets } from '@fluentui/react';
import * as React from 'react';

const css = mergeStyleSets({
    footer: {
        textAlign: "center",
        fontSize: FontSizes.xSmall,
        with: "100%",
        backgroundColor: "#E4E4E7"
    }
});

const NavFoot = () => {
    return (
        <div className={css.footer} >
                            &copy; Tony Wieser, 2021<br />
                            Tuesday, 16 November 2021
            </div>
                        );
            }
            
export default NavFoot;
