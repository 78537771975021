import { FontSizes, FontWeights, getTheme, mergeStyleSets } from '@fluentui/react';
import * as React from 'react';
import { Branding } from './Branding';
import { globalCss } from './utils/globalStyles';
import { SafePureComponent } from './utils/SafePureComponent';

const theme = getTheme();

const css = mergeStyleSets({
    header: {
        width: "100%",
        boxShadow: `0 10px 20px -12px rgba(0, 0, 0, 0.42),
0 3px 20px 0 rgba(0, 0, 0, 0.12),
0 8px 10px -5px rgba(0, 0, 0, 0.2)`,

        backgroundColor: theme.palette.white,
        fontWeight: FontWeights.semibold,
        fontSize: FontSizes.large,
        lineHeight: "2em",
        color: theme.palette.black,
        display: "block",
    },
    logo: {
        maxHeight: "70px",
        width: "auto",
        paddingRight: "10px",
        display: "inline-block",
    },
})

export class Header extends SafePureComponent {
    public render() {
        return <div className={css.header}>
            <Branding />
            <div className={globalCss.constrainedContent}>
                {this.props.children}
            </div>
        </div>;
    }
}
