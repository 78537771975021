import { mergeStyleSets } from '@fluentui/react';
import * as React from 'react';
import { globalCss } from './utils/globalStyles';
import { SafePureComponent } from './utils/SafePureComponent';

const css = mergeStyleSets({
    branding: {
        float: "left",
    },
    logo: {
        maxHeight: "70px",
        width: "auto",
        paddingRight: "10px",
        display: "inline-block",
    },
})

export class Branding extends SafePureComponent {
    public render() {
        return <div className={globalCss.constrainedContent}>
            <a href="https://www.camsailingclub.org.uk" title="return to public site">
                <img className={css.logo} src="/csc-logo.jpg" alt="Cam Sailing Club Logo" />
            </a>
            <span style={{verticalAlign: "top"}}>Cam Sailing Club Member Area</span>
        </div>;
    }
}
