import { IResponse } from "./Data";

export interface IGuestRecord {
    id: string; // the id guest booking record
    memberId: string; // the id of the member booking
    date: string;
    name: string;   // the name of the guest
    postcode: string; // the postcode of the guest.
}

export interface IGuestResponse extends IResponse {
    returnType: "guests",
    results: IGuestRecord[];
}

export function isGuestResponse(r: IResponse): r is IGuestResponse {
    return (r.returnType === "guests");
}
