import * as React from 'react';
import { PrimaryButton, TextField, ChoiceGroup, IChoiceGroupOption, mergeStyleSets, IChoiceGroupStyles, DetailsList, Selection, IColumn, MarqueeSelection } from '@fluentui/react';
import { SafePureComponent } from 'utils/SafePureComponent';
import { Api, IUser } from 'Api/Data';
import { Permissions } from 'utils/Permissions';
import { Redirect } from 'react-router-dom';

const css = mergeStyleSets({
    container: {
        selectors: {
            ".ms-ChoiceField-wrapper": {
                paddingRight: "1em"
            },
            ".ms-ChoiceFieldGroup": {
                display: "flex",
                alignItems: "center",
                selectors: {
                    ".ms-Label": {
                        marginTop: "8px",
                        paddingRight: "1em"
                    }
                }
            },
        }
    }
});

const choiceGroupStyle = { flexContainer: { display: "flex" } } as IChoiceGroupStyles;

const initialState = {
    userList: [] as IUser[],
    audience: "everyone",
    sentTo: undefined as string | undefined
};

export class SendEmail extends SafePureComponent<unknown, typeof initialState> {
    public form = React.createRef<HTMLFormElement>();

    public state = initialState;

    private _selection: Selection;
    private _columns: IColumn[];

    public constructor(props: unknown) {
        super(props);
        this._selection = new Selection({
            onSelectionChanged: () => console.log("Selection Changed")
        });
        this._columns = [
            { key: "f", name: "First Name", fieldName: "first_name", minWidth: 50, maxWidth: 75 },
            { key: "l", name: "Last Name", fieldName: "last_name", minWidth: 150 },
        ];
    }

    private handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        const { userList } = this.state;

        event.preventDefault();
        const data = new FormData(this.form.current!);

        console.log(this._selection.getItems());
        const items = this._selection.getSelectedIndices().map((i) => 
            parseInt(userList[i].user_id, 10)
        );
        data.append("selection", JSON.stringify(items));

        for (const pair of data.entries()) {
            console.log(pair[0] + ', ' + pair[1]);
        }

        const response = await fetch('https://members.cottenham-brass.co.uk/cors-cgi/get_users.php', {
            method: 'POST',
            mode: 'cors',
            credentials: 'include',
            body: data,
        });

        if (response.ok) {
            const responseText = await response.text();
            console.log(responseText);
            if (responseText !== "Sorry") {
                this.safe.setState({ sentTo: responseText });
            }
        }
    }

    public componentDidMount = async () => {
        super.componentDidMount();
        const userList = await Api.Instance.getUsers();
        if (userList !== undefined) {
            this.safe.setState({ userList: userList.results });
        }
    }

    private audienceChanged = (evt?: React.FormEvent<HTMLElement | HTMLInputElement>, option?: IChoiceGroupOption) => {
        this.safe.setState({ audience: option!.key });  
    }

    private sendAnother = () => {
        this.safe.setState({ sentTo: undefined });
    }

    private getUserKey = (user: IUser) => {
        return user.user_id;
    }

    public render() {
        const { audience, userList, sentTo } = this.state;

        if (!Permissions.Instance.canSendEmail) {
            return <Redirect to="/" />;
        }

        return (sentTo === undefined) ? <>
            <h1>Send an email to all of the members</h1>
            <form className={css.container} ref={this.form} onSubmit={this.handleSubmit} style={{ margin: "auto" }}>
                <ChoiceGroup label="Send An Email To:" defaultSelectedKey={audience}
                    name="audience" onChange={this.audienceChanged}
                    styles={choiceGroupStyle}
                    options={[
                        {
                            key: "everyone",
                            text: 'Everyone',
                            value: "everyone"
                        },
                        {
                            key: "committee",
                            text: 'Just the committee',
                            value: "committee"
                        },
                        {
                            key: "select",
                            disabled: userList.length === 0,
                            value: "select",
                            text: 'Select people'
                        }
                    ] as unknown as IChoiceGroupOption[]}
                />
                {audience === "select" && userList.length !== 0 &&
                    <MarqueeSelection selection={this._selection} isDraggingConstrainedToRoot={true} >
                    <DetailsList compact={true} items={userList} columns={this._columns}
                        getKey={this.getUserKey}
                            selection={this._selection} selectionPreservedOnEmptyClick={true}

                        />
                    </MarqueeSelection>
                    }
                <TextField underlined label="Subject:" name="subject" type="text" />
                <br />
                <TextField underlined multiline={true} autoAdjustHeight label="Message:" placeholder="type your email here..." name="msg" />
                <PrimaryButton disabled={sentTo !== undefined} type="submit">Send</PrimaryButton>
            </form>
            <hr />
        </> : <>
                <h2>You’ve successfully sent the email to</h2>
                <div dangerouslySetInnerHTML={{ __html: sentTo }} />
                <PrimaryButton onClick={this.sendAnother}>Clear</PrimaryButton>
            </>;
    }
}
