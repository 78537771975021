import * as React from 'react';
import { SafePureComponent } from '../utils/SafePureComponent';
import { PrimaryButton } from '@fluentui/react';
import { Redirect } from 'react-router';
import { ILoginProps } from './Login';
import { ApiBasePath } from "Api/ApiBase";

export class Logout extends SafePureComponent<ILoginProps, any> {

    public form = React.createRef<HTMLFormElement>()

    private handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(this.form.current!);
        console.log(data);

        const response = await fetch(`${ApiBasePath}/logout.php`, {
            method: 'POST',
            mode: 'cors',
            credentials: 'include',
        });

        this.redirect(<Redirect to="/" />);
        if (response.ok) {
            this.props.loginChange(await response.text())
        } else {
            console.log("something failed?");
        }
    }


    public render() {
        return this.redirectElement || <>
            <h1>Logout</h1>
            <form ref={this.form} onSubmit={this.handleSubmit} style={{ width: "50%", margin: "auto" }}>
                <p>Click the button below to log out</p>
                <PrimaryButton type="submit">Log Out</PrimaryButton>
            </form>
            <hr />
            </>;
    }
}
