import './App.css';

import React, { Suspense } from 'react';
import { ApiBasePath } from "Api/ApiBase";

import { Body } from './Body';
import { BodyMin } from './BodyMin';
import NavFoot from './NavFoot';
import NavMain from './NavMain';
import NavMin from './NavMin';

import { SafePureComponent } from './utils/SafePureComponent';
import { globalStyles } from './utils/globalStyles';
import { Loading } from './Components/Loading';
import { Login } from './Components/Login';
import { Switch, Route } from 'react-router';
import { Permissions } from './utils/Permissions';
import { mergeStyleSets } from '@fluentui/react';
import { Header } from './Header';

const css = mergeStyleSets({
    global: {
        textAlign: "left",
        margin: "0 auto",
        position: "relative",
    },
    wrapper: {
        selectors: {
            a: {
                ...globalStyles.linkSelectors.selectors.a,
            }
        }
    }
})

export interface ILoginDetails {
    needsLogin: boolean;
    id?: string;
    username?: string;
    first_name?: string;
    last_name?: string;
    last_login?: string;
    group_id?: string;
    email?: string;
}

const initialState = Object.freeze({
    needsLogin: true,
} as ILoginDetails);

class App extends SafePureComponent<any, typeof initialState> {
    public state = initialState;

    public componentDidMount() {
        super.componentDidMount();
        this.refreshLoginState();
    }

    public loginChange = (resp: string) => {
        console.log("loginChange", resp);
        const response = JSON.parse(resp) as typeof initialState;
        const { needsLogin, id, username } = response;
        Permissions.Instance.currentDetails = response;
        this.safe.setState({ needsLogin, id, username });
    }

    private async refreshLoginState() {
        const resp = await fetch(`${ApiBasePath}/loggedin.php`,
            {
                method: 'GET',
                mode: 'cors',
                credentials: 'include',

            });
        if (resp.ok) {
            const r = await resp.text();
            this.loginChange(r);
        }
    }


    public render() {
        const { needsLogin } = this.state;

        return (<div className={css.global}>
            {(needsLogin) ?
                // routes unprotected by login
                <Switch>
                    <Route path="/resetPassword/:id?" render={(props) =>
                        <Login loginChange={this.loginChange} resetId={props.match.params.id} />} />
                    <Route render={() => <>
                        <Header>
                            <NavMin />
                        </Header>

                        <div className={css.wrapper} >
                            <BodyMin loginChange={this.loginChange} />
                        </div>
                        <NavFoot />
                    </>} />
                    <Route render={() => <Login loginChange={this.loginChange} />} />
                </Switch> :
                <Suspense fallback={<Loading />}>
                    <Switch>
                        <Route render={() => <>
                            <Header>
                                <NavMain />
                            </Header>

                            <div className={css.wrapper} >
                                <Body loginChange={this.loginChange} />
                            </div>
                            <NavFoot />
                        </>} />
                    </Switch>

                </Suspense>}
        </div>
        );
    }
}

export default App;
