import { FontWeights, getTheme, mergeStyleSets } from "@fluentui/react";

const theme = getTheme();

export const globalStyles = {
    linkSelectors: {
        selectors: {
            a: {
                textDecoration: "none",
                color: theme.palette.themePrimary,
                selectors: {
                    ":hover, :active, :active:hover": {
                        color: theme.palette.themeDarker,
                        fontWeight: FontWeights.semibold,
                        textDecoration: "underline",
                    },
                    ":focus": {
                        fontWeight: FontWeights.semibold,
                        textDecoration: "underline",
                        color: theme.palette.themePrimary
                    },
                    ".isDisabled": {
                        color: theme.palette.neutralTertiary,
                        pointerEvents: "none",
                        cursor: "default"
                    }
                }
            }
        }
    },
    reverseLinkSelectors: {
        selectors: {
            a: {
                color: theme.palette.neutralLight,
                textDecoration: "none",
                selectors: {
                    ":active, :focus, :hover": {
                        textDecoration: "underline",
                        fontWeight: FontWeights.bold
                    }
                }
            },
        }
    },
}

export const globalCss = mergeStyleSets({
    constrainedContent: {
        maxWidth: "1140px",
        marginLeft: "auto",
        marginRight: "auto",
        paddingLeft: "1em",
        paddingRight: "1em",
    }
});
