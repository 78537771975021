import * as React from 'react';
import { SafePureComponent } from './utils/SafePureComponent';
import { Link } from 'react-router-dom';
import { mergeStyleSets } from '@fluentui/react';

const css = mergeStyleSets({
    info: {
        marginBottom: "6pt",
        selectors: {
            p: {
                margin: "0cm",
                marginTop: "6pt",
                
            }
        }
    }
});

export class Welcome extends SafePureComponent {


    public render() {
        return <div className={css.info}>
            <h2>Member Website News</h2>
            <p>Welcome to the prototype Cam Sailing Club member area.</p>
            <p>Next year we will be trialling a password protected member area that
                will allow you to book club boats, camping pitches and the workshop, as well
                as be able to sign in guests and update your contact information.</p>
            <p>Over the coming years, we hope to add additional functionality to the site</p>

            <h2>Things to do today!</h2>
            <p>Please visit the <Link to="/agminfo">AGM Documents</Link> page to see relevant documents for the AGM.</p>
            <p>Also, please see the <Link to="/unlabelled">Unlabelled Property</Link> page to see if there&rsquo;s something of yours that might be disposed of.</p>



        </div>;
    }
}
        
export default Welcome;
