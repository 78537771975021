import { ActionButton, mergeStyleSets, MessageBar, MessageBarType, NeutralColors, PrimaryButton, TextField } from '@fluentui/react';
import * as React from 'react';
import { Api } from '../Api/Data';
import { IGuestRecord } from '../Api/GuestApi';
import { SafePureComponent } from '../utils/SafePureComponent';

const css = mergeStyleSets({
    itemGroup: {
        borderRadius: "5px",
        backgroundColor: NeutralColors.gray40,
        margin: "1em",
        padding: ".5em",
        selectors: {
            "label": {
                width: "12em",
            },
        }
    },
    itemHeader: {
        display: "flex",
        alignItems: "baseline",
        justifyContent: "space-between",
    }
});

const initialState = {
    guests: [] as Partial<IGuestRecord>[],
    dirty: false,
    refresh: 0,
    feedbackMessageBarType: undefined as MessageBarType | undefined,
    feedbackMessageBarMessage: "" as string | JSX.Element,
}

function changeHandler<T>(store: T, e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue: any) {
    // there must be a better way...
    const key = (e.target as HTMLInputElement | HTMLTextAreaElement).name as keyof T;
    store[key] = newValue;
    console.log("dirty", key, newValue);
}

interface IGuestProp {
    guest: Partial<IGuestRecord>;
    idx: number;
    handleDelete: (idx: number) => void;
}

class GuestForm extends SafePureComponent<IGuestProp> {
    private handleChange = (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        changeHandler(this.props.guest, e, newValue);
    }

    public render() {
        const { guest, idx, handleDelete } = this.props;
        return <div className={css.itemGroup} key={idx + 1}>
            <div className={css.itemHeader}>
                <h3>Guest {idx + 1}</h3>
                <ActionButton iconProps={{ iconName: "Delete" }} onClick={() => handleDelete(idx)}>Delete Guest</ActionButton>
            </div>
            <TextField label="Guest Name" name="name" underlined defaultValue={guest.name} onChange={this.handleChange} />
            <TextField label="Postcode" name="postcode" underlined defaultValue={guest.postcode} onChange={this.handleChange} />
        </div>;
    }
}


export class Guests extends SafePureComponent {
    public state = initialState;

    private addGuest = () => {
        const { guests} = this.state;
        this.safe.setState({
            guests: [...guests, {} as Partial<IGuestRecord>],
            dirty: true
        });
    }
    private deleteGuest = (idx: number) => {
        const { guests } = this.state;
        guests.splice(idx, 1);
        this.safe.setState({
            guests: guests.slice()
        });
    }

    private enableFeedbackMessageBar(type: MessageBarType, message: string | JSX.Element) {
        this.safe.setState({ feedbackMessageBarMessage: message, feedbackMessageBarType: type });
    }

    private handleSubmit = async (_event: React.MouseEvent<HTMLButtonElement>) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const _ret = await Api.Instance.postGuests(this.state.guests);
        this.safe.setState({ dirty: false });
        this.enableFeedbackMessageBar(MessageBarType.success, "Details saved.")
    }



    public render() {
        const { guests, feedbackMessageBarType, feedbackMessageBarMessage, dirty } = this.state;
        return <div>
            <h1>Register a guest</h1>
            <p>All guests must be registered when attending the club, and may only attend a maximum of 4 times per year</p>
            <p>Please register each guest attending below</p>
            <fieldset>
                <legend>Guests</legend>
                {
                    guests.map((v, i) => {
                        return <GuestForm key={i} guest={v} idx={i} handleDelete={this.deleteGuest} />
                    })
                }
                <ActionButton iconProps={{ iconName: "Add" }} onClick={this.addGuest}>Add Guest</ActionButton>

                <div style={{ display: "flex", alignItems: "baseline" }}>
                    <PrimaryButton value="Send" disabled={!dirty} onClick={this.handleSubmit} > Update</PrimaryButton>
                    {feedbackMessageBarType !== undefined && <MessageBar style={{ marginLeft: "1em" }} messageBarType={feedbackMessageBarType}>
                        {feedbackMessageBarMessage}
                    </MessageBar>}
                </div>


            </fieldset>
        </div>;
    }
}
