import { ILoginDetails } from "../App";


enum userGroup {
    user = 1 << 0,
    committee = 1 << 1,
    trustworthy = 1 << 2,
    rehearsals = 1 << 3,
    admin = 1 << 8

}
export class Permissions {
    static _instance: Permissions;
    private _currentDetails: ILoginDetails;

    
    public static get Instance() {
        if (!Permissions._instance) {
            Permissions._instance = new Permissions();
        }
        return Permissions._instance;
    }

    public set currentDetails(newDetails: ILoginDetails) {
        this._currentDetails = newDetails;
    }

    public get groupId() {
        return this._currentDetails && Number(this._currentDetails.group_id);
    }

    public get isAdmin() {
        return (this.groupId & userGroup.admin) !== 0;
    }

    public get isCommittee() {
        return (this.groupId & userGroup.committee) !== 0 || this.isAdmin;
    }

    public get isTrustworthy() {
        return (this.groupId & userGroup.trustworthy) !== 0 || this.isAdmin;
    }

    public get canApproveRehearsals() {
        return (this.groupId & userGroup.rehearsals) !== 0 || this.isAdmin;
    }

    public get canEditGigs() {
        return this.isTrustworthy;
    }

    public get canSendEmail() {
        return this.isCommittee;
    }

    private constructor() {
        this._currentDetails = {
            needsLogin: true,
        } as ILoginDetails;
    }
}
