import { FontSizes, FontWeights, getTheme, mergeStyleSets } from '@fluentui/react';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { globalStyles } from './utils/globalStyles';

const theme = getTheme();

const css = mergeStyleSets({
    header: {
        textAlign: "center",
        backgroundColor: theme.palette.white,
        fontWeight: FontWeights.semibold,
        fontSize: FontSizes.large,
        lineHeight: "2em",
        color: theme.palette.black,
        width: "100%",

        selectors: {
            a: {
                flexBasis: "22%",
                ...globalStyles.linkSelectors.selectors.a,
            },
        }
    },
    menuArea: {
        marginLeft: "auto",
        marginRight: "auto",
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between",
    },
    path: {
        fontSize: FontSizes.large,
        color: theme.palette.neutralSecondary,
        backgroundColor: theme.palette.neutralLight,
        width: "100%",
        selectors: {
            a: {
                marginLeft: "1em",
                ...globalStyles.linkSelectors.selectors.a,
            }
        }
    }
});

const NavMin = () => {
    return (<>
        <nav className={css.header}>
            <div className={css.menuArea}>
                <Link to="/">Home</Link>
                <Link to="/agminfo">AGM Documents</Link>
                <Link to="/unlabelled">Unlabelled Property</Link>
            </div>
            </nav>
        </>);
                }
                
export default NavMin;
