import * as React from 'react';
import { SafePureComponent } from '../utils/SafePureComponent';
import { PrimaryButton, TextField, Checkbox, DefaultButton, ITextField, Label, Link, mergeStyleSets } from '@fluentui/react';
import { Header } from '../Header';
import { ApiBasePath } from "Api/ApiBase";

const css = mergeStyleSets({
    container: {
        maxWidth: "1140px",
        marginRight: "auto",
        marginLeft: "auto",
        position: "relative",
    }
});
export interface ILoginProps {
    loginChange: (loginResponse: string) => void;
    resetId?: string;
}

const initialState = {
    resetStatus: null as JSX.Element | null
}

const divStyle: React.CSSProperties = {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "1em"
};

export class Login extends SafePureComponent<ILoginProps, typeof initialState> {
    public state = initialState; 

    private form = React.createRef<HTMLFormElement>();
    private usernameField = React.createRef<ITextField>();

    private handleSubmit = async (event: React.SyntheticEvent<any>, isReset?: boolean) => {
        const { resetId } = this.props;
        const currentUserName = this.usernameField.current!.value;

        event.preventDefault();
        const data = new FormData(this.form.current!);
        console.log(data);

        if (resetId) {
            // use the reset flow...
            console.log("Reset flow");
            if (!currentUserName?.includes("@")) {
                // looks like it might be an email address...
                const response = await fetch(`${ApiBasePath}/changepassword.php?c=${resetId}`, {
                    method: 'POST',
                    mode: 'cors',
                    credentials: 'include',
                    body: data,
                });

                if (response.ok) {
                    const resp = await response.json();
                    console.log("Response was", resp);
                    if (resp.status === "success") {
                        this.safe.setState({
                            resetStatus: <div>Success.  Please <Link href="/" >Login</Link></div>
                        });
                    } else {
                        this.safe.setState({
                            resetStatus: <div>
                                <Label>Sorry, password change failed</Label>
                                <div style={{ whiteSpace: "pre" }}>{JSON.stringify(resp.errors)}</div>
                            </div>
                        });
                    }
                }
            } else {
                this.safe.setState({
                    resetStatus: <div>
                        <Label>Use your username</Label>
                        <p>You use your username to change your password, not email address.</p>
                    </div>
                })
            }

        }
        else if (isReset) {
            if (currentUserName?.includes("@")) {
                // looks like it might be an email address...
                const response = await fetch(`${ApiBasePath}/resetpassword.php`, {
                    method: 'POST',
                    mode: 'cors',
                    credentials: 'include',
                    body: data,
                });

                if (response.ok) {
                    const resp = await response.json();
                    const success = (resp.status === "success");
                    this.safe.setState({
                        resetStatus: <div>
                            <Label>{success ? "A password reset has been sent" : "Sorry, that didn't work"}</Label>
                            <p>{success ? "Check your spam folder if it hasn't arrived before contacting the admin." : resp.message}</p>
                        </div>
                    });
                } else {
                    this.safe.setState({
                        resetStatus: <div>
                            <Label>The server did not respond correctly</Label>
                            <p>Please contact the site admin.</p>
                        </div>
                    });
                }
            }
            else {
                this.safe.setState({
                    resetStatus: <div>
                        <Label>Missing Email Address</Label>
                        <p>You use the email address you registered with to reset your password.</p>
                    </div>
                })
            }
        } else {
            const response = await fetch(`${ApiBasePath}/login.php`, {
                method: 'POST',
                mode: 'cors',
                credentials: 'include',
                body: data,
            });

            if (response.ok) {
                this.props.loginChange(await response.text())
            }
        }
    }


    public render() {
        const { resetId } = this.props;
        const label = !resetId ? "Password or Phone number" : "New Password";
        const signin = !resetId ? "Sign in" : "Set new password";
        return <>
            <Header />
            <div className={css.container}>
                <h1>{resetId ? "Reset Password" : "Login"}</h1>
                <form ref={this.form} onSubmit={this.handleSubmit} style={{ margin: "auto" }}>
                    <TextField componentRef={this.usernameField} underlined label="Username or Email:" name="username" type="text" />

                    {!resetId && <><br />
                        <Checkbox label="Remember me?" name="auto" /></>}
                    <br />
                    <TextField underlined label={label} placeholder="use this format: 01223 000000" name="password" type="password" />
                    {resetId && <TextField underlined label="Confirm Password" placeholder="Type the same password again..." name="password2" type="password" />}
                    <div style={divStyle}>
                        <PrimaryButton type="submit">{signin}</PrimaryButton>
                        {!resetId && <DefaultButton onClick={(event) => {
                            this.handleSubmit(event, true);
                        }}> Reset Password!</DefaultButton>}
                    </div>
                </form>
                <hr />
                {this.state.resetStatus}
            </div>
        </>;
    }
}
